var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-content fc"},[_c('draggable',{staticClass:"select-list flex1 borderA-bottom",attrs:{"options":{
      group: 'assembly',
      animation: 150,
      ghostClass: 'sortable-ghost',
      chosenClass: 'chosenClass',
      scroll: true,
      scrollSensitivity: 200,
    }},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},_vm._l((_vm.list),function(item,index){return _c('el-col',{key:index,staticClass:"borderA"},[_c('div',{staticClass:"item-left",on:{"click":function($event){return _vm.handleClickSort(index)}}},[(_vm.sortIndex === index)?_c('el-input',{key:item.assembly_id,ref:"sortRef",refInFor:true,attrs:{"autofocus":true},on:{"blur":_vm.handleInputBlur},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleInputEnter(index)}},model:{value:(_vm.sortNumber),callback:function ($$v) {_vm.sortNumber=$$v},expression:"sortNumber"}}):_c('span',[_vm._v(_vm._s(index + 1))])],1),_c('div',{staticClass:"item-right",on:{"dblclick":function($event){return _vm.handleDbClick(item)}}},[_c('div',{staticClass:"item-text-h1"},[_vm._v(" "+_vm._s(item.assemblyName)+" ")]),_c('div',[_vm._v("组件ID："+_vm._s(item.assemblyId))]),_c('div',[_vm._v("组件类型："+_vm._s(item.typeName))]),_c('div',[_vm._v("组件描述："+_vm._s(item.description))])]),_c('i',{staticClass:"del el-icon-delete cursor",on:{"click":function($event){return _vm.removeItem(index)}}})])}),1),_c('div',{staticClass:"tail"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleSave}},[_vm._v("保存")]),_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":function($event){_vm.list = []}}},[_vm._v("清空")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }